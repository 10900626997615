import "regenerator-runtime/runtime";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

const connector = new AppSearchAPIConnector({
  searchKey: "search-doeqormbd918qqhih9khkvnp",
  engineName: `${import.meta.env.VITE_ELASTICSEARCH_PREFIX}-feed-item-2`,
  endpointBase: "https://notedsource-a.ent.us-east1.gcp.elastic-cloud.com",
});

const config = {
  debug: true,
  apiConnector: connector,
  searchQuery: {
    // disjunctiveFacets: ["research_interests.research_interest"],
    facets: {
      journal_title: {
        type: "value",
      },
      "subdisciplines.name": {
        type: "value",
      },
      "disciplines.name": {
        type: "value",
      },
    },
  },
};

export default config;
